@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans&display=swap');

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Sofia Sans', sans-serif;
  /* margin: 0 auto; */
  /* width: 100%; */
}

a {
  text-decoration: none;
  color: rgb(61, 146, 201);
}
a:hover,
a:focus {
  text-decoration: underline;
}

h3 {
  font-weight: 100;
}


.pure-img-responsive {
  max-width: auto;
  height: auto;
}

#layout {
  padding: 0;
}

.header {
  text-align: center;
  top: auto;
  margin: 3em auto;
}

/* .header .navbar {
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
} */
.navbar{
  display: flex;
  background-color: royalblue;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 100%;
  text-align: center;
  padding: 0px 15px 0px 15px;
  justify-content: center;
  align-items: center;
  box-shadow: inset;
  color: white;
  position: sticky;
}

.navbar-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.navbar-logo {
  padding: 5px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.navbar-logo-text {
  font-size: 20px;
  font-weight: bold;
}



.brand-title,
.brand-tagline {
  margin: 0;
}
.brand-title {
  text-transform: uppercase;
}
.brand-tagline {
  font-weight: 300;
  color: rgb(176, 202, 219);
}

.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-item {
  display: inline-block;
  *display: inline;
  zoom: 1;
}
.nav-item a {
  background: transparent;
  border: 2px solid rgb(176, 202, 219);
  color: #fff;
  margin-top: 1em;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 85%;
}
.nav-item a:hover,
.nav-item a:focus {
  border: 2px solid rgb(61, 146, 201);
  text-decoration: none;
}

.content-subhead {
  text-transform: uppercase;
  color: #aaa;
  border-bottom: 1px solid #eee;
  padding: 0.4em 0;
  font-size: 80%;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.content {
  padding: 2em 1em 0;
}

.post {
  padding-bottom: 2em;
}
.post-title {
  font-size: 2em;
  color: #222;
  margin-bottom: 0.2em;
}
.post-logo {
  border-radius: 0px;
  float: right;
  margin-left: 1em;
  margin-bottom: 1.6em;
}
.post-description {
  font-family: Georgia, "Cambria", serif;
  color: #444;
  line-height: 1.8em;
}
.post-meta {
  color: #999;
  font-size: 90%;
  margin: 0;
}

.post-category {
  margin: 0 0.1em;
  padding: 0.3em 1em;
  color: #fff;
  background: #999;
  font-size: 80%;
}

  .post-category-js {
      background: #F0DB4F;
  }

.post-images {
  margin: 1em 0;
}


.footer {
  padding: 1em 0;
}
.footer a {
  color: rgb(139, 136, 136);
  font-size: 80%;
}
.footer .pure-menu a:hover,
.footer .pure-menu a:focus {
  background: none;
}

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.a:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

@media (min-width: 48em) {
  .content {
      padding: 2em 3em 0;
      margin: 0 25% 0 25%;
  }

  .header {
      margin: 80% 2em 0;
      text-align: right;
  }


  .footer {
      text-align: center;
  }
}